import Loadable from '@loadable/component';
import { css } from 'astroturf';
import React, { Component } from 'react';

import PageHeader from '../components/PageHeader';
import SEO from '../components/SEO';
import Link from '../components/link';
import Layout from '../layout';

import CTA2 from '../components/CTA';

const ColorContrastImprover = Loadable(() => import('../components/ColorContrastImprover'));

const styles = css`
  .cta {
    display: none;
    align-items: center;
    color: #fff;
    line-height: 1;
    background: #353546;
    border-radius: 50px;
    padding: 6px 8px 6px 6px;
    transition: background-color 0.2s ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.024) 0px 0px 0px 1px, rgba(0, 0, 0, 0.05) 0px 1px 0px, rgba(0, 0, 0, 0.03) 0px 0px 8px,
      rgba(0, 0, 0, 0.1) 0px 20px 30px;

    @media (min-width: 42rem) {
      display: inline-flex;
    }

    & b {
      background: #fff;
      color: #353546;
      text-transform: uppercase;
      font-size: 0.6rem;
      padding: 6px;
      border-radius: 50px;
      transition: color 0.2s ease-in-out;
      margin-right: 0.5rem;
    }

    & strong {
      line-height: 24px;
    }

    & svg {
      margin-left: 0.5rem;
      vertical-align: middle;
      stroke: currentcolor;
      stroke-width: 2;
    }

    &:hocus {
      background-color: rgb(16, 125, 181);
      transition: background-color 0.2s ease-in-out;
      & b {
        color: rgb(16, 125, 181);
      }
    }
  }
`;
class Page extends Component {
  render() {
    return (
      <Layout>
        <SEO
          title="Color contrast checker, analyser and color suggestions"
          keywords={['css', 'wcag', 'apca', 'contrast', 'analyse', 'color constrast checker']}
          pathname={this.props.location.pathname}
          description="Check the contrast between colors using WCAG or APCA and get suggestions for better colors if needed."
          ogFileName="color-contrast"
          ogCustomPath="/og-custom/"
        />
        <PageHeader>
          <h1>Color contrast checker</h1>
          <h2>Check and suggests colors to meet the required WCAG or APCA contrast ratio.</h2>
          <a href="https://colorcontrast.app" className={styles.cta}>
            <b>New</b> Our color contrast checker is also available at{' '}
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" width="24" height="24" viewBox="0 0 24 24">
              <path d="M16.418 4.157a8 8 0 0 0 0 15.686"></path>
              <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0"></path>
            </svg>
            <strong>ColorContrast.App</strong>
          </a>
        </PageHeader>
        <ColorContrastImprover />
        <div className="page childPadding" style={{ marginTop: '4rem' }}>
          <h3>How does the color contrast checker work?</h3>
          <p>
            The Polypane color contrast checker checks against values defined by the WCAG 2 (Web Content Accessibility
            Guidelines) or APCA (the Accessible Perceptual Contrast Algorithm). WCAG give a formula to calculate the
            contrast, ranging from 1 (no contrast) to 21 (black on white). APCA has a range from minus ~106 to plus
            ~106.
          </p>
          <p>
            The Polypane contrast checker takes opacity into account when calculating the colors, giving you the real
            contrast ratio.
          </p>
          <h3>How are the suggested colors calculated?</h3>
          <p>
            When the contrast is not sufficient, we'll suggest you similar colors that do have enough contrast. We do
            this by increasing or decreasing the luminance of the foreground color until it has enough contrast. When
            the foreground color can not be changed (because it's too close to full black or full white) we will suggest
            an accessible background color instead.
          </p>

          <h3>How much contrast do I need?</h3>
          <p>The WCAG has multiple levels, and the relevant ones for us are called AA and AAA.</p>
          <p>
            AA is an acceptable level of accessibility, and AAA is one step above: the most complete accessibility
            ruleset there is.
          </p>

          <h4>AA Level</h4>
          <ul>
            <li>
              <strong>4.5</strong>: For normal text.
            </li>
            <li>
              <strong>3</strong>: For large (24px or more) or bold (19px*) text.
            </li>
          </ul>

          <p>
            <em>For non-text elements, like icons, charts and controls, a minimum contrast of 3 is needed.</em>
          </p>

          <h4>AAA Level</h4>
          <ul>
            <li>
              <strong>7</strong>: For normal text.
            </li>
            <li>
              <strong>4.5</strong>: For large (24px or more) or bold (19px*) text.
            </li>
          </ul>
          <p>
            <em>* this is actually 18.66 pixels, rounded up.</em>
          </p>

          <h4>APCA</h4>
          <p>
            <strong>
              <em>APCA is not part of any current standard, so shouldn't be used for production work.</em>
            </strong>
          </p>
          <p>
            APCA is an alternative color contrast algorithm that might end up replacing the WCAG algorithm. It is more
            advanced and takes into account which color is used for the text. You can score between 0 and roughly 106.
            The higher the absolute score, the better the contrast. The negative value just means you're using a
            light-on-dark color combination.
          </p>
          <p>
            Scoring in APCA is dependent on the font and font-weight used. In this color picker we use a simplified
            scoring method based on the Bronze level:
          </p>
          <ul>
            <li>
              <strong>75</strong>: For normal body text.
            </li>
            <li>
              <strong>60</strong>: For medium-size (24px or more) or bold (16px) text.
            </li>
            <li>
              <strong>45</strong>: For large (36px or more) or bold (24px or more) or non-text elements.
            </li>
          </ul>
          <p>
            For a more advanced scoring table, check out{' '}
            <a href="https://www.myndex.com/APCA/">the official APCA calculator</a>.
          </p>
          <p>
            For more information on web design terminology, check out our{' '}
            <Link to="/responsive-design-glossary/">Responsive design glossary</Link>. For our other free tools, go to{' '}
            <Link to="/resources/">Resources and tools</Link>. Start a{' '}
            <a href="https://dashboard.polypane.app/register">free Polypane trial</a> and get color suggestions directly
            in your browser.
          </p>
        </div>
        <CTA2 />
      </Layout>
    );
  }
}

export default Page;
